<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th
            colspan="2"
            class="font-weight-medium white--text text-heading-3 text-center sales-breakdown-table-header"
          >
            {{ $t("sales_breakdown") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(sale, index) in sales" :key="`sale-${index}`">
          <td class="font-weight-regular main--text lighten-2 text-heading-1">
            {{ sale.name }}
            <template v-if="sale.subtype !== 'O'">
              <v-chip
                :color="chipBackgroundColor"
                :text-color="chipTextColor"
                x-small
                class="font-weight-bold px-2"
              >
                {{ sale.subtype }}
              </v-chip>
            </template>
          </td>
          <td
            class="font-weight-regular main--text lighten-2 text-heading-1 text-right"
          >
            {{ sale.amount | toCurrency }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "TableSalesBreakdown",
  props: {
    sales: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  computed: {
    chipBackgroundColor() {
      return "#e8ffe0";
    },
    chipTextColor() {
      return "#38761d";
    }
  }
};
</script>

<style scoped>
.sales-breakdown-table-header {
  background-color: #495bc4 !important;
}
</style>
