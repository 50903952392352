<template>
  <v-data-table
    class="monthly-sales-costs-table"
    :headers="headers"
    :items="items"
    :items-per-page="12"
    mobile-breakpoint="0"
    :single-expand="true"
    :expanded.sync="expanded"
    :item-class="getItemClass"
    item-key="month"
    show-expand
    expand-icon="$blueDownArrow"
    hide-default-footer
    dense
    @click:row="openRow"
  >
    <template v-slot:item.totalSales="{ item }">
      {{ item.totalSales | toCurrency }}
    </template>
    <template v-slot:item.totalCosts="{ item }">
      {{ item.totalCosts | toCurrency }}
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="py-4 px-8">
        <v-row id="expandedRow">
          <v-col cols="12">
            <TableSalesBreakdown class="breakdown-table" :sales="item.sales" />
          </v-col>

          <v-col cols="12">
            <TableCostsBreakdown
              class="breakdown-table"
              :costs="item.costs"
              :month="item.id"
            />
          </v-col>
        </v-row>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import TableSalesBreakdown from "@/components/ui/TableSalesBreakdown";
import TableCostsBreakdown from "@/components/ui/TableCostsBreakdown";
export default {
  name: "TableMonthlySalesAndCosts",
  components: { TableCostsBreakdown, TableSalesBreakdown },
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data() {
    return {
      expanded: [],
      headers: [
        {
          text: "",
          width: 80,
          value: "month",
          align: "center",
          cellClass: "px-0 font-weight-regular text-heading-3",
          sortable: false,
          divider: false
        },
        {
          text: this.$t("sales"),
          width: 80,
          value: "totalSales",
          align: "center",
          class: "font-weight-medium main--text lighten-2 text-heading-2",
          cellClass:
            "px-2 font-weight-regular text-heading-4 monthly-sales-costs-table-sales-cell",
          sortable: false,
          divider: false
        },
        {
          text: this.$t("costs"),
          width: 80,
          value: "totalCosts",
          align: "center",
          class: "font-weight-medium main--text lighten-2 text-heading-2",
          cellClass:
            "px-2 font-weight-regular text-heading-4 monthly-sales-costs-table-costs-cell",
          sortable: false,
          divider: false
        },
        {
          text: "",
          width: 10,
          value: "data-table-expand",
          align: "center",
          cellClass: "px-0",
          divider: false
        }
      ]
    };
  },
  methods: {
    getItemClass(item) {
      let index = this.items.indexOf(item);
      return this.ifEven(index) ? "monthly-sales-costs-table-even-row" : "";
    },
    ifEven(number) {
      return number % 2 === 0;
    },
    openRow(item, data) {
      data.expand(!data.isExpanded);
      this.$nextTick(() => {
        if (!data.isExpanded) {
          this.$vuetify.goTo("#expandedRow", {
            duration: 1000,
            offset: 38,
            easing: "easeInOutCubic"
          });
        }
      });
    },
    getMonth(item) {
      return parseInt(item.id.split("-")[1]);
    }
  }
};
</script>

<style scoped>
.v-data-table >>> .v-icon__component {
  width: 10px;
  height: 10px;
}
</style>

<style>
.monthly-sales-costs-table-even-row {
  background-color: #eff5ff !important;
}

.monthly-sales-costs-table-sales-cell {
  color: #495bc4 !important;
}

.monthly-sales-costs-table-costs-cell {
  color: #747448 !important;
}

.v-data-table.monthly-sales-costs-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: unset !important;
}

.v-data-table.monthly-sales-costs-table:not(.breakdown-table)
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:not(.v-data-table__mobile-row) {
  border: unset !important;
}

.v-data-table.monthly-sales-costs-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  border: unset !important;
}

.v-data-table.breakdown-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  border-bottom: 0.75px solid #afc0da !important;
}
</style>
