<template>
  <v-container fluid class="px-0">
    <MonthlyReportCard />
  </v-container>
</template>

<script>
import MonthlyReportCard from "@/components/layout/sales-and-costs/MonthlyReportCard";
export default {
  name: "MonthlySalesAndCosts",
  components: { MonthlyReportCard }
};
</script>

<style scoped></style>
