<script>
import { mixins } from "vue-chartjs";
const { reactiveProp } = mixins;
import RoundedBar from "@/plugins/charts/RoundedCornerBar";
import { formatThousands } from "@/filters";

export default {
  name: "ChartBarSalesAndCosts",
  extends: RoundedBar,
  mixins: [reactiveProp],
  props: {
    itemsData: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data() {
    return {
      chartOptions: {
        title: {
          display: true,
          text: ""
        },
        layout: {
          padding: {
            left: 20,
            right: 20
          }
        },
        barRoundness: 0.4,
        responsive: true,
        devicePixelRatio: 1,
        maintainAspectRatio: true,
        showTooltips: false,
        tooltips: {
          enabled: false,
          mode: "index",
          backgroundColor: "#EEEEEE",
          titleFontFamily: "Roboto",
          titleFontColor: "#000",
          bodyFontFamily: "Roboto",
          bodyFontColor: "#000",
          caretSize: 0,
          displayColors: false,
          borderColor: "#DFDFDF",
          borderWidth: 1,
          cornerRadius: 5,
          custom: this.renderTooltips
        },
        legend: {
          display: false,
          position: "bottom",
          labels: {
            fontSize: 14,
            fontFamily: "Roboto"
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                zeroLineColor: "transparent",
                display: false,
                drawOnChartArea: false
              },
              ticks: {
                fontColor: "#444444",
                fontFamily: "Roboto",
                fontSize: 14
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                color: "transparent",
                display: true,
                drawBorder: false,
                zeroLineColor: "#ccc",
                zeroLineWidth: 2
              },
              display: true,
              ticks: {
                display: false,
                beginAtZero: true,
                steps: 10,
                stepValue: 5,
                max: 100
              }
            }
          ]
        },
        plugins: {
          datalabels: false,
          customAnnotationLabel: false,
          deferred: {
            xOffset: 150,
            yOffset: "50%",
            delay: 500
          }
        }
      }
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  },
  methods: {
    renderTooltips(tooltipModel) {
      let tooltipEl = document.getElementById("chartTooltip");
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }
      let dataIndex = tooltipModel.dataPoints[0].index;

      if (tooltipModel.body) {
        let sales = this.itemsData[dataIndex].sales;
        let costs = this.itemsData[dataIndex].costs;
        let innerHtml = `<div class="font-weight-medium main--text lighten-2 text-heading-1">
          ${this.$t("sales")}:
          <span class="main--text lighten-1">${formatThousands(sales)}円</span >
          </div>`;

        innerHtml += `<div class="font-weight-medium main--text lighten-2 text-heading-1">
        ${this.$t("costs")}: 
        <span class="main--text lighten-1">${formatThousands(costs)}円</span>
        </div>`;
        tooltipEl.innerHTML = innerHtml;
      }

      tooltipEl.style.opacity = 1;
      tooltipEl.style.position = "absolute";
      if (dataIndex < 3) {
        tooltipEl.style.left = tooltipModel.caretX + 40 + "px";
      } else {
        tooltipEl.style.left = tooltipModel.caretX - 135 + "px";
      }
      tooltipEl.style.top = tooltipModel.y + 150 + "px";
      tooltipEl.style.pointerEvents = "none";
    }
  }
};
</script>

<style scoped></style>
