<template>
  <v-row>
    <v-col cols="12">
      <v-row align="center" class="pl-2">
        <v-col cols="auto" class="px-1">
          <div class="chart-legend-box sales"></div>
        </v-col>

        <v-col cols="auto" class="px-1">
          <span
            class="font-weight-medium main--text lighten-2 text-heading-3"
            style="line-height: 15px"
          >
            {{ $t("sales") }}
          </span>
        </v-col>

        <v-col cols="auto" class="px-1">
          <div class="chart-legend-box costs"></div>
        </v-col>

        <v-col cols="auto" class="px-1">
          <span
            class="font-weight-medium main--text lighten-2 text-heading-3"
            style="line-height: 15px"
          >
            {{ $t("costs") }}
          </span>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="text-center">
      <ChartBarSalesAndCosts
        :height="280"
        :chart-data="chartData"
        :items-data="itemsData"
      />
    </v-col>

    <div class="card-tooltip" id="chartTooltip"></div>
  </v-row>
</template>

<script>
import ChartBarSalesAndCosts from "@/components/charts/ChartBarSalesAndCosts";
export default {
  name: "SalesAndCostsChart",
  components: { ChartBarSalesAndCosts },
  props: {
    chartData: {
      type: Object,
      default: () => {},
      required: true
    },
    itemsData: {
      type: Array,
      default: () => [],
      required: true
    }
  }
};
</script>

<style scoped>
.chart-legend-box.sales {
  background-color: #495bc4;
}

.chart-legend-box.costs {
  background-color: #e9cf75;
}

.card-tooltip {
  position: absolute;
  opacity: 0;
  left: 160px;
  top: 125px;
  min-width: 125px;
  padding: 6px 6px;
  border-style: solid;
  border-radius: 5px !important;
  border-width: 1px !important;
  border-color: #dfdfdf !important;
  background-color: #eeeeee !important;
  /*box-shadow: 1px 1px 3px 1px #d7d7d7;*/
}
</style>
