<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th
            colspan="2"
            class="font-weight-medium white--text text-heading-3 text-center costs-breakdown-table-header"
          >
            {{ $t("costs_breakdown") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(cost, index) in costs"
          :key="`cost-${index}`"
          class="main--text lighten-2 font-weight-regular text-heading-1 "
          :class="getRowClass(cost)"
          @click="cost.subtype === 'F' ? foodCostDetail(cost) : null"
        >
          <td>
            {{ cost.name }}
            <template v-if="cost.subtype !== 'O'">
              <v-chip
                :color="chipBackgroundColor(cost.subtype)"
                :text-color="chipTextColor(cost.subtype)"
                x-small
                class="font-weight-bold px-2"
              >
                {{ cost.subtype }}
              </v-chip>
            </template>
          </td>
          <td class="text-right">
            {{ cost.amount | toCurrency }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "TableCostsBreakdown",
  props: {
    costs: {
      type: Array,
      default: () => [],
      required: true
    },
    month: {
      type: String,
      default: "",
      required: true
    }
  },
  methods: {
    chipBackgroundColor(subtype) {
      return subtype === "F" ? "#e8ffe0" : "#fce5cdff";
    },
    chipTextColor(subtype) {
      return subtype === "F" ? "#38761d" : "#b45f06";
    },
    foodCostDetail(cost) {
      if (cost.subtype !== "F") return;

      this.$router.push({
        name: "daily_food_sales_and_costs",
        params: { month: this.month }
      });
    },
    getRowClass(item) {
      let type = item.subtype;
      if (type === "F") return "row-clickable";
    }
  }
};
</script>

<style scoped>
.costs-breakdown-table-header {
  background-color: #e9cf75 !important;
}
.row-clickable {
  cursor: pointer;
  text-decoration: underline;
  color: blue !important;
}
</style>
