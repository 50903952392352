<template>
  <CardBase
    :title="$t('sales_and_costs_monthly_title')"
    route-destination="/sales-costs/daily"
    :route-text="$t('sales_and_costs_monthly_route_text')"
    :help-button="false"
    :custom-date="date"
  >
    <template v-slot:content-body>
      <v-row>
        <v-col cols="12">
          <div class="text-center">
            <SalesAndCostsChart
              v-if="renderChart"
              :chart-data="chartData"
              :items-data="lastSixMonthsSalesAndCosts"
            />
          </div>
        </v-col>

        <v-col cols="12">
          <TableMonthlySalesAndCosts :items="salesAndCosts" />
        </v-col>

        <v-col cols="12">
          <div class="font-weight-regular main--text lighten-3 text-heading-2">
            {{ $t("sales_and_costs_message") }}
          </div>
        </v-col>
      </v-row>
    </template>
  </CardBase>
</template>

<script>
import CardBase from "@/components/ui/CardBase";
import TableMonthlySalesAndCosts from "@/components/ui/TableMonthlySalesAndCosts";
import SalesAndCostsChart from "@/components/ui/SalesAndCostsChart";
import { mapGetters } from "vuex";
export default {
  name: "MonthlyReportCard",
  components: { SalesAndCostsChart, TableMonthlySalesAndCosts, CardBase },
  created() {
    this.$store.dispatch("SALES_COSTS_MONTHLY_GET").then(() => {
      this.mapChartValues();
      this.mapTableValues();
      this.$nextTick(() => {
        this.renderChart = true;
      });
    });
  },
  computed: {
    ...mapGetters([
      "getUserReport",
      "getSalesCostsMonthlyData",
      "getApiProcessingStatus"
    ]),
    maxValue() {
      return this.getUserReport.max_month_sale >
        this.getUserReport.max_month_cost
        ? this.getUserReport.max_month_sale
        : this.getUserReport.max_month_cost;
    },
    chartData() {
      return {
        labels: this.chartLabels,
        datasets: this.chartDatasets
      };
    },
    date() {
      return this.getUserReport.date;
    }
  },
  data() {
    return {
      lastSixMonthsSalesAndCosts: [],
      salesAndCosts: [],
      chartDatasets: [],
      chartLabels: [],
      renderChart: false
    };
  },
  methods: {
    calculatePercentage(maxValue, value) {
      return Math.round((value / maxValue) * 100);
    },

    mapTableValues() {
      let salesAndCosts = [];
      for (const deal of this.getSalesCostsMonthlyData.deals) {
        salesAndCosts.push({
          id: deal.month.id,
          month: deal.month.long_name,
          totalSales: deal.sale,
          totalCosts: deal.cost,
          sales: deal.sale_deals,
          costs: deal.cost_deals
        });
      }
      this.salesAndCosts = salesAndCosts;
    },

    mapChartValues() {
      let salesData = [];
      let costsData = [];
      let salesAndCosts = [];
      let chartLabels = [];

      for (const deal of this.getUserReport.monthly_deals) {
        salesData.push(this.calculatePercentage(this.maxValue, deal.sale));
        costsData.push(this.calculatePercentage(this.maxValue, deal.cost));
        salesAndCosts.push({ sales: deal.sale, costs: deal.cost });
        chartLabels.push(deal.month.name);
      }

      this.chartDatasets = [
        {
          label: "Sales",
          backgroundColor: "#576dd9",
          borderColor: "#576dd9",
          data: salesData,
          barPercentage: 1
        },
        {
          label: "Costs",
          backgroundColor: "#E9CF75",
          borderColor: "#E9CF75",
          data: costsData,
          barPercentage: 1
        }
      ];

      this.lastSixMonthsSalesAndCosts = salesAndCosts;
      this.chartLabels = chartLabels;
    }
  }
};
</script>

<style scoped></style>
