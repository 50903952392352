var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"monthly-sales-costs-table",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":12,"mobile-breakpoint":"0","single-expand":true,"expanded":_vm.expanded,"item-class":_vm.getItemClass,"item-key":"month","show-expand":"","expand-icon":"$blueDownArrow","hide-default-footer":"","dense":""},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.openRow},scopedSlots:_vm._u([{key:"item.totalSales",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.totalSales))+" ")]}},{key:"item.totalCosts",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.totalCosts))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-4 px-8",attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"id":"expandedRow"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('TableSalesBreakdown',{staticClass:"breakdown-table",attrs:{"sales":item.sales}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('TableCostsBreakdown',{staticClass:"breakdown-table",attrs:{"costs":item.costs,"month":item.id}})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }